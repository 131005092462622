<template>

  <div>
    <div class="margin-page">

      <div id="d1" style="display:none;z-index: 1000;position: relative;">
        <ModalAlwaysShow
          tipo='error'
          :messageErr="internalizationSelectAcc.selectAccErrTimeOutLabel"
          :detailErr="internalizationSelectAcc.selectAccErrTimeOutMsg"
        />
      </div>
          
      <PanelWBorder :titulo="internalizationSelectAcc.selectAccUpBoxTitle" :pstyle="panel" :ptstyle="paneltitle" :pcstyle="panelcontent">
  
        <PanelRectangle 
          :baserecstyle="baseRec" 
          :recstyle="rec" 
          style="display: var(--selectacc-box-up-rectangle-display)"
        />
        <hr class="hrselectacc">

        <div class="margin-slot">
          <div class="radio-outer selectAccText" v-for="item in  listAccounts" :key="item.id" >
            Ag: {{item.account_agency}} | Cc: {{item.account_number}} <br>
            
            <div class="radio" v-for="opts in item.account_type" :key="opts.account_id">
            
              <RadioButton class="radioP" name="op1" :value="opts.account_id" v-model="selected" :required="true"/>
              <label class="selectAccLabel selectAccText"> {{opts.account_type}}</label>
            
            </div>
          </div>
        </div>
      
      </PanelWBorder>
              
      <div class="wraper-buttons">
        
        <Mbutton 
          :rotulo="internalizationSelectAcc.selectAccConfirmButtonLabel"
          class="accountconfirmbutton"
          tipo="button"
          :confirmacao="false"
          @botaoAtivado="onSubmit()"
        />

        <div class="separator-button">

          <Cbutton 
            :rotulo="internalizationSelectAcc.selectAccCancelButtonLabel"
            class="accountcancelbutton"
            tipo="button"
            :confirmacao="false"
            @botaoAtivado="cancelGoToView()"
          />

        </div>
        
      </div>    
      
    </div>

    <ExceptionModal
      ref="exceptionWarnig2"
      tipo='error'
      :messageErr="internalizationSelectAcc.selectAccErrCommunicationFailLabel"
      :detailErr="internalizationSelectAcc.selectAccErrCommunicationFailMsg"
    />

    <ExceptionModalOKNoRedirect
      ref="noAccSelected"
      tipo='error'
      messageErr=""
      :detailErr="internalizationSelectAcc.selectAccModalChooseAccMsg"
    />

  </div>
</template>

<script>
import { getWithExpiry, getInternalization, endSess } from '@/util/util.js';

/* Internalization, used if not use BD to get data 
var internalizationSelectAccTmp = {

  "selectAccUpBoxTitle": "Selecione sua Conta",
  "selectAccConfirmButtonLabel": "Confirmar",
  "selectAccCancelButtonLabel": "Cancelar",

  "selectAccErrTimeOutLabel": "Tempo Excedido",
  "selectAccErrTimeOutMsg": "Você atingiu o limite de 05 minutos sem executar nenhuma ação.",

  "selectAccErrCommunicationFailLabel": "Falha na comunicação.",
  "selectAccErrCommunicationFailMsg": "Houve falha na comunicação. Inicie a solicitação de consentimento novamente.",

};
/* */

function account_in_list(list, acc_number){
  var in_list = 0;
  list.forEach(element => {
    if(element.account_number == acc_number){
      in_list = 1;
    }
  });
  return in_list;
}

function agency_in_list(list, ag_number){
  var in_list = 0;
  list.forEach(element => {
    if(element.ag_number == ag_number){
      in_list= 1;
    }
  });
  return in_list;
}

export default {
   
  data()  {
      
    return {
      labelP: 'Valor a pagar',
      panel:{
        'border': '1px solid var(--selectacc-box-border-color)',
        'background-color': 'var(--selectacc-box-up-backgorund-color)',
        'box-shadow': 'var(--selectacc-box-shadow)',
        'filter': 'var(--selectacc-box-filter)'
      },
      paneltitle:{
        'color': 'var(--selectacc-box-up-title-color)'
      },
      panelcontent:{ 
        'background': 'var(--selectacc-box-down-background-color)',
        'border': '3px solid var(--selectacc-box-border-color)',
        'padding': '0px 10px 0px 10px'
      },
      ftype:{ 
        'font-size':'16px',
        'font-weight':'bold'
      },
      vshow : false,
      selected:null,
      redirectTo:'ConfirmConsent',
      listAccounts:[],
      accountTypes:{
        'CACC' : 'Conta corrente',
        'SLRY' : 'Conta salário',
        'SVGS' : 'Conta poupança',
        'TRAN' : 'Conta de pagamento pré-paga'
      },
      baseRec:{},
      rec:{
        'background-color': 'var(--selectacc-box-hrrectangle-color)' 
      },
      onLine:true,
    }
  },
  methods: {
      
    onSubmit(event){
      //console.log(event)

      if(this.isEmpty(this.selected)){
        this.showNoSelectModal();
        return;
      }
      else{
        //this.$router.push({name: this.redirectTo, })
        this.$router.push({
          name: this.redirectTo,
          params: {data: this.selected}
        });
      }
    },
    checkOnline(){
      this.onLine = navigator.onLine ?true:false
      if(!navigator.onLine){
        endSess('dados');
        this.$refs.exceptionWarnig2.enable();
      }
    },
    closeDialog(){
      console.log(this.selected);
    },
    cancelGoToView() {
      this.$router.push({name: this.redirectTo});
    },
    showLoadingModal(){
      this.$root.$refs.loadingModal.enable();
    },
    defTimeOut(){
      var timeout;

      function refresh(){
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex";
        },  300000);
      }
      refresh();
      document.addEventListener('click', refresh);
    },
    isEmpty(text){
      if (text === 'undefined' || text == null || !text) 
        return true;
      
      return text.replace(/\s/g, '').length < 1;
    },
    showNoSelectModal(){
      this.$refs.noAccSelected.enable();
    },
  },
  created() {

    this.internalizationSelectAcc = getInternalization();

    this.defTimeOut();
    this.$root.$refs.loadingModal.enable();
    // GET request using fetch with error handling
    
    //const holder = JSON.parse(sessionStorage.getItem('dados'))
    const holder = JSON.parse(getWithExpiry('dados'));
    if(!holder){
      this.$root.$refs.loadingModal.disable();
      this.$root.$refs.exceptionWarnigFetchingDataPay.enable();
    }
    else{

      setInterval(() => {
        this.checkOnline();
      }, 3000);

      this.selected = holder.account_id;
      this.payload = holder;

      for(const info of holder.accounts){

        if (this.isEmpty(info.account_id) || this.isEmpty(info.account_number) || this.isEmpty(info.account_agency) || this.isEmpty(info.account_type))
          continue;

        //temp object to hold the relevant data
        var obj = {
          "account_agency":info.account_agency,
          "account_number":info.account_number,
          "account_type"  :[],
        }
        //pairs account id with display account type

        var kvp={"account_id":info.account_id,"account_type":this.accountTypes[info.account_type]};
        obj.account_type.push(kvp);

        //case there's no previews entry of account x in ag y
        if (account_in_list(this.listAccounts ,obj.account_number) == 0 && agency_in_list(this.listAccounts ,obj.account_agency) == 0){
          this.listAccounts.push(obj);
        }
        //case there's a entry for account x in ag y with a diff type
        else{
          this.listAccounts.forEach(element => {
            if(element.account_agency == obj.account_agency && obj.account_number == element.account_number){
              element.account_type.push(kvp);
            }
          });
        }
      }
      this.$root.$refs.loadingModal.disable();
    }
  },
  mounted(){
    this.$root.$refs.mainNavbar.showGoBackButton();

    if(this.$route != null && this.$route.params != null && this.$route.params.showNoSelectModal == 'true'){
      //console.log('show');
      this.showNoSelectModal();
    }
  }
}
</script>

<style scoped>
.margin-slot{
    padding-left: 18px;
    padding-bottom: 80px;
    padding-right: 22px;
}
.radio{
    padding-top: 29px;
}
.radio-outer{
    padding-top: 29px;
}
.margin-page{
    padding-left:21px ;
    padding-right: 27px;
    padding-top: 27px;
}
.wraper-buttons{
    padding-top: 37px;
}
.separator-button{
    padding-top: 27px; 
}
.hrselectacc{
    margin-bottom: 0px;
    height: 0.5px;
    border: 0;
    display: var(--selectacc-box-up-hr-display);
    background-color: var(--selectacc-box-hrrectangle-color);
}
.selectAccText{
    color: var(--selectacc-box-text-color);
}
.selectAccLabel{
    margin-left: 5px;
}
</style>